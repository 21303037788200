import React from "react"

const PasswordHelpText: React.FC<{ show: boolean }> = ({ show }) => {
  if (!show) return null
  return (
    <div className="text-xs text-gray-600">
      <p className="">Password should contain:</p>
      <ul className="list-disc px-3 pb-2">
        <li className="mb-0">At least 8 characters</li>
        <li className="mb-0">At least one number</li>
        <li className="mb-0">
          At least one special character (e.g. @, #, etc.)
        </li>
        <li className="mb-0">At least one uppercase letter</li>
        <li className="mb-0">At least one lowercase letter</li>
      </ul>
    </div>
  )
}

export default PasswordHelpText

// import { graphql } from "gatsby"
import React from "react"
import { navigate } from "gatsby"
import styled from "styled-components"
import logoImg from "images/logo.svg"

const LogoImage = styled.img`
  margin: 0;
  cursor: pointer;
`
const AppLogo: React.FC<{ width: number }> = ({ width }) => {
  // const style = { width }
  // const gqlQueryAppLogo_FluidImage = graphql`
  //   query {
  //     placeholderImage: file(relativePath: { eq: "app-logo.png" }) {
  //       childImageSharp {
  //         fluid(maxWidth: 100) {
  //           ...GatsbyImageSharpFluid
  //         }
  //       }
  //     }
  //   }
  // `

  return (
    // <StaticQuery query={gqlQueryAppLogo_FluidImage}>
    //   {data => (
    //     <Link to={"/"}>
    //       <div className="bg-white rounded-full">
    //         <Img
    //           style={style}
    //           alt={"AskPCOS"}
    //           fluid={data.placeholderImage.childImageSharp.fluid}
    //         />
    //       </div>
    //     </Link>
    //   )}
    // </StaticQuery>
    <LogoImage src={logoImg} onClick={() => navigate("/")} />
  )
}

export default AppLogo

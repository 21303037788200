import React from "react"
import ClockLoader from "react-spinners/ClockLoader"

import SubmitButton from "./SubmitButton"

const PleaseWaitButton = () => {
  return (
    <SubmitButton
      title="Please wait"
      Icon={<ClockLoader size={16} color={"white"} loading={true} />}
    />
  )
}

export default PleaseWaitButton

import React from "react"

import { FaExclamationTriangle } from "react-icons/fa"

const ErrorText: React.FC<{ text: string | null; leadingText?: boolean }> = ({
  text,
  leadingText,
}) => {
  if (!text) return null
  return (
    <p
      className={`flex  bg-red-600 text-white px-3 py-1 rounded text-sm mb-2 items-center ${
        !leadingText && "leading-none"
      }`}
    >
      <span className="py-1">
        <FaExclamationTriangle />
      </span>
      <span className="px-1" />
      <span>{text}</span>
    </p>
  )
}

export default ErrorText

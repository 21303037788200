import React from "react"

const Input: React.FC<{ [id: string]: any }> = props => {
  const { className, ...otherProps } = props
  const localClassName = `border p-1 px-3 focus:shadow-outline rounded bg-gray-100 
                          text-site-blue-dark w-full outline-none hover:border-gray-400`
  return (
    <div>
      <input
        {...otherProps}
        className={[className, localClassName].join(" ")}
        style={{ backgroundImage: "linear-gradient(transparent, transparent)" }}
      />
    </div>
  )
}

export default Input

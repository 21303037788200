import React from "react"

const SubmitButton: React.FC<{ title: string; Icon: any }> = ({
  title,
  Icon,
}) => (
  <button
    type="submit"
    className={`
      flex items-center px-3 py-2
      button-bg-site-pink text-white rounded-full text-sm
    `}
  >
    <span>{Icon}</span>
    <span className="px-1" />
    <span>{title}</span>
  </button>
)

export default SubmitButton

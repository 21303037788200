import React from "react"

import Input from "./Input"

interface iTextInput {
  label: any
  onChange: (e: any) => void
  placeholder: string
  value: any
  type: string
  error: boolean
  disable: boolean
}

const TextInput: React.FC<iTextInput> = ({
  label,
  onChange,
  placeholder,
  value,
  type,
  disable,
  error,
}) => {
  return (
    <>
      <p className="text-sm flex item-center mb-1">{label}</p>
      <Input
        type={type}
        value={value}
        onChange={(e: any) => {
          if (disable) return
          onChange(e)
        }}
        placeholder={placeholder}
        className={error ? "border-red-600 hover:border-red-700" : ""}
      />
    </>
  )
}

export default TextInput

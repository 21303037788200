import { Auth } from "aws-amplify"
import { navigate } from "gatsby"
import React, { useState, useEffect } from "react"
import ForcedChangePasswordForm from "./ForcedChangePasswordForm"
import { withAmplifyAuth } from "./withAmplifyAuth"

const ChangePwdForm: React.FC<{ Auth: typeof Auth }> = ({ Auth }) => {
  const [userAttributes, setUserAttributes] = useState<any>(null)
  const [successText, setSuccessText] = useState<string | null>(null)

  useEffect(() => {
    Auth.currentAuthenticatedUser().then(data => {
      setUserAttributes(data)
    })
  }, [])

  const onPasswordChangeSuccess = () => {
    setSuccessText("Password successfully changed.")
    navigate("/login/")
  }

  return (
    <>
      <ForcedChangePasswordForm
        show={true}
        onSuccess={onPasswordChangeSuccess}
        userAttributes={userAttributes}
        Auth={Auth}
      />
    </>
  )
}

export default withAmplifyAuth(ChangePwdForm)

import { Link } from "gatsby"
import React from "react"

import { FaHome } from "react-icons/fa"
import { WhiteAppButton } from "../buttons"
import AppLogo from "../logos"
import { CardWrapper } from "./base"

const Logo: React.FC<{ text: string }> = ({ text }) => (
  <div className="flex items-center">
    <div className="rounded-full">
      <AppLogo width={42} />
    </div>
    <h1 className=" ml-2 text-site-blue-dark border-l pl-2 text-sm mb-0">
      {text}
    </h1>
  </div>
)

const RegistrationFormCard: React.FC<{
  children: any
  title_1: string
  title_2: string
}> = ({ children, title_1, title_2 }) => {
  return (
    <CardWrapper rounded={false} className="shadow-lg">
      <div className="px-6 py-8">
        <div className="flex items-center justify-between">
          <Logo text={title_1} />
          <Link to="/">
            <WhiteAppButton
              Icon={<FaHome />}
              className="bg-gray-100 no-border"
            />
          </Link>
        </div>
        <h1 className="text-site-blue-dark py-3 text-4xl sm:text-3xl mb-0">
          {title_2}
        </h1>
        {children}
      </div>
    </CardWrapper>
  )
}

export default RegistrationFormCard

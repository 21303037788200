import { CognitoUserSession } from "amazon-cognito-identity-js"
import { Auth } from "aws-amplify"
import React, { useState } from "react"

import { FaCog } from "react-icons/fa"
import ErrorText from "../ErrorText"
import RegistrationFormCard from "../cards/RegistrationFormCard"
import Form from "../form/Form"
import InputSeperator from "../form/InputSeperator"
import PleaseWaitButton from "../form/PleaseWaitButton"
import SubmitButton from "../form/SubmitButton"
import TextInput from "../form/TextInput"
import PasswordHelpText from "./PasswordHelpText"

interface iChangePasswordForm {
  show: boolean
  userAttributes: any
  onSuccess: () => void
  Auth: typeof Auth
}

interface iChangePasswordFormData {
  confirm_password: string
  new_password: string
  old_password: string
}

enum ErrorType {
  old_password,
  confirm_password,
  new_password,
}

interface iChangePasswordFormError {
  type: ErrorType | null
  text: string | null
}

const ChangePasswordFormOnSubmit: React.FC<{ loading: boolean }> = ({
  loading,
}) => {
  if (!loading) return <SubmitButton title="Update" Icon={<FaCog />} />
  return <PleaseWaitButton />
}

const ForcedChangePasswordFormView: React.FC<{
  onSubmit: (data: iChangePasswordFormData) => void
  error: iChangePasswordFormError
  loading: boolean
}> = ({ onSubmit, error, loading }) => {
  return (
    <RegistrationFormCard
      title_1="Ask Early Menopause"
      title_2="Update Password"
    >
      <Form
        data={
          {
            confirm_password: "",
            new_password: "",
            old_password: "",
          } as iChangePasswordFormData
        }
        onSubmit={onSubmit}
      >
        {(data, setData) => {
          return (
            <div className="text-site-blue-dark">
              <ErrorText text={error.text} />
              <TextInput
                label="Password"
                onChange={(e: any) => setData("old_password", e.target.value)}
                placeholder="Please enter you current password"
                value={data.old_password}
                type="password"
                error={error.type === ErrorType.old_password}
                disable={loading}
              />
              <InputSeperator />
              <TextInput
                label="New password"
                onChange={(e: any) => setData("new_password", e.target.value)}
                placeholder="Enter the new password"
                value={data.new_password}
                type="password"
                error={error.type === ErrorType.new_password}
                disable={loading}
              />
              <InputSeperator />
              <PasswordHelpText show={data.new_password.length > 0} />
              <TextInput
                label="Confirm password"
                onChange={(e: any) =>
                  setData("confirm_password", e.target.value)
                }
                placeholder="Confirm the password"
                value={data.confirm_password}
                type="password"
                error={error.type === ErrorType.confirm_password}
                disable={loading}
              />
              <InputSeperator />
              <InputSeperator />
              <ChangePasswordFormOnSubmit loading={loading} />
            </div>
          )
        }}
      </Form>
    </RegistrationFormCard>
  )
}

const ForcedChangePasswordForm: React.FC<iChangePasswordForm> = ({
  show,
  userAttributes,
  onSuccess,
  Auth,
}) => {
  if (!show) return null
  const [error, setError] = useState<iChangePasswordFormError>({
    type: null,
    text: null,
  })

  const [loading, setLoading] = useState(false)

  const validateInput = (new_password: string, confirm_password: string) => {
    if (!new_password)
      return {
        type: ErrorType.new_password,
        text: "Please enter the new password",
      }
    if (new_password.length < 8)
      return {
        type: ErrorType.new_password,
        text: "The password should contain at least 8 characters.",
      }
    if (!confirm_password)
      return {
        type: ErrorType.confirm_password,
        text: "Please confirm the password",
      }
    if (confirm_password !== new_password) {
      return {
        type: ErrorType.confirm_password,
        text: "The passwords don't match",
      }
    }
    return null
  }

  const onSubmit = ({
    confirm_password,
    new_password,
    old_password,
  }: iChangePasswordFormData) => {
    if (loading) return
    setError({
      type: null,
      text: null,
    })
    const err = validateInput(new_password, confirm_password)
    if (err) {
      setError(err)
      return
    }

    delete userAttributes.email_verified
    delete userAttributes.phone_number_verified
    setLoading(true)
    Auth.changePassword(userAttributes, old_password, new_password)
      .then(() => {
        setLoading(false)
        onSuccess()
      })
      .catch(err => {
        setLoading(false)
        setError({
          type: null,
          text: err.message,
        })
      })
  }

  return (
    <ForcedChangePasswordFormView
      onSubmit={onSubmit}
      error={error}
      loading={loading}
    />
  )
}

export default ForcedChangePasswordForm

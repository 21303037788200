import SEO from "components/seo"
import React from "react"
import ChangePwdPage from "components/registration/ChangepwdPage/ChangepwdPage"
import theme from "components/layout/theme"

const ChangePwd = () => {
  return (
    <div style={{ background: theme.colors.primary }}>
      <SEO title="Change password" />
      <ChangePwdPage />
    </div>
  )
}

export default ChangePwd
